export default {
  search: "Search",
  displayNameLabel: "Display Name",
  emailAddressLabel: "Email Address",
  accountLabel: "Account",
  accountsLabel: "Accounts",
  nameDashboard: "Name your dashboard",
  chartType: "Chart Type",
  chartTitleLabel: "Chart Title",
  summaryTitleLabel: "Summary Title",
  decompositionMetric: "Decomposition Metric",
  icons: "Icons",
  legend: "Legend",
  categoryLabel: "Category Label",
  tableTitleLable: "Table Title",
  accountFilterLabel: "Filter by account",
  roleFilterLabel: "Filter by role",
  agencyFilterLabel: "Filter by agency",
  countryFilterLabel: "Filter by country",
  defaultNewPageName: "New Page",
  all: "All",
  targetValue: "Target Value",
  countSelected: (count: number): string => `${count} selected`,
};

import { isEmpty } from "lodash";
import { ActionContext } from "vuex";

import Api from "@/api/Api";
import Lang from "@/lang";
import { API_ROUTES, requestTokens } from "@/settings/apiRoutes";
import { campaignBudget } from "@/settings/dashboardFilters";
import store, { RootState } from "@/store";

export type Catalogs = Record<string, string[]>;

export interface CatalogsState {
  catalogs: Catalogs | null;
  isLoading: boolean;
}

interface CatalogsDictionary {
  [key: string]: string;
}

const catalogsDictionary: CatalogsDictionary = {
  advertisers: "advertiser",
  agencies: "agency",
  business_goals: "businessGoal",
  campaigns: "campaign",
  campaign_kpis: "campaignKpi",
  campaign_ids: "campaignId",
  channels: "channel",
  industries: "industry",
};

const catalogsDefault: Catalogs = {
  advertiser: [],
  agency: [],
  businessGoal: [],
  campaign: [],
  channel: [],
  campaignKpi: [],
  campaignId: [],
  industry: [],
};

const catalogState: CatalogsState = {
  catalogs: null,
  isLoading: false,
};

export default {
  namespaced: true,

  state: catalogState,

  mutations: {
    updateCatalogs(state: CatalogsState, catalogs: Catalogs): void {
      state.catalogs = catalogs;
    },

    updateLoading(state: CatalogsState, val: boolean): void {
      state.isLoading = val;
    },

    resetCatalog(state: CatalogsState) {
      state.catalogs = null;
    },
  },

  actions: {
    async fetchCatalogs(ctx: ActionContext<CatalogsState, RootState>): Promise<void> {
      ctx.commit("updateLoading", true);
      type CatalogsData = Record<string, string[] | Record<string, string>[]>;
      const filterData = {} as CatalogsData;
      const res = await Api.request<CatalogsData>({
        path: API_ROUTES.CATALOG.PATH,
        defaultResult: {},
        errorText: Lang.errorGetCatalogs,
        errorCallback: async () => {
          await ctx.commit("updateCatalogs", catalogsDefault);
        },
        token: requestTokens.apiCatalogs,
      });

      if (res.campaign_ids) {
        res.campaign_ids = Array.from(
          new Set((res.campaign_ids as Record<string, string>[]).map((value: Record<string, string>) => value.id))
        );
      }

      if (isEmpty(res)) {
        store.commit("app/showAlert", { message: Lang.errorGetCatalogs, type: "error" });
      } else {
        for (const key in res) {
          const dictionaryKey = catalogsDictionary[key as string];
          if (!dictionaryKey) {
            continue;
          }
          filterData[dictionaryKey] = res[key]?.sort();
          (res[key] as string[]).unshift("All");
          if (Array.isArray(filterData[dictionaryKey])) {
            filterData[dictionaryKey] = [...new Set(filterData[dictionaryKey] as [])];
          }
        }

        filterData.campaignBudget = campaignBudget.map((item) => item.text);

        ctx.commit("updateCatalogs", filterData);
      }

      ctx.commit("updateLoading", false);
    },
  },

  getters: {
    catalogs(state: CatalogsState): Catalogs | null {
      return state.catalogs;
    },

    isLoading(state: CatalogsState): boolean {
      return state.isLoading;
    },
  },
};
